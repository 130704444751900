@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.search {
  background-color: var(--kp-color-fill-interactive-primary);
  border-radius: 0 0.2rem 0.2rem 0;
  height: 3.6rem;
  width: 4.4rem;
  min-width: 4.4rem;
  margin: 0;
  border-left: 0.1rem solid var(--kp-color-fill-interactive-primary);
  border: 0.1rem solid var(--kp-color-fill-interactive-primary);
  transition: background-color 0.3s ease;
  box-shadow: var(--shadow-3);

  @include respond-below(sm) {
    height: 4.4rem;
    border-radius: 0 0.4rem 0.4rem 0;
  }
}

.search:hover {
  border: 0.1rem solid var(--kp-color-fill-interactive-primary-hover);
}
