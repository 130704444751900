@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.holder {
  position: relative;
}

/* Customize the label (the container) */
.container {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  color: var(--kp-color-content-regular-primary);
}

/* Hide the browser's default checkbox */
.container input,
.container input[type='checkbox'] {
  margin-right: 2.4rem;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.6rem;
  width: 1.6rem;
  background-color: var(--kp-color-form-bg-input);
  border: 0.1rem solid var(--kp-color-form-border-default);
  border-radius: 2px;
  box-shadow: var(--shadow-3);
  transition: all 100ms;

  @include respond-below(sm) {
    height: 2rem;
    width: 2rem;
  }
}

/* On mouse-over, add a grey background color */
.container:hover input:not(:disabled) ~ .checkmark {
  border: 0.1rem solid var(--kp-color-form-border-hover);
  background-color: var(--kp-color-form-bg-control-hover);
  @include respond-below(sm) {
    background-color: var(--kp-color-form-bg-input);
  }
}

.container input:focus ~ .checkmark {
  border: 0.1rem solid var(--kp-color-form-border-hover);
  background-color: var(--kp-color-form-bg-control-hover);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid var(--kp-color-content-onFill);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);

  @include respond-below(sm) {
    left: 6px;
    width: 6px;
    height: 12px;
  }
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.container input:checked ~ .checkmark {
  background-color: var(--kp-color-form-bg-active);
  border: 0.1rem solid var(--kp-color-form-border-active);
}

.container:hover input:checked ~ .checkmark {
  background-color: var(--kp-color-form-bg-active);
  border: 0.1rem solid var(--kp-color-form-border-active);
}

.container input:checked ~ .label {
  font-weight: bold;
}

.container input:disabled ~ .label {
  color: var(--kp-color-disabled-dim);
}

.label {
  line-height: 1.85rem;
  @include respond-below(sm) {
    font-size: $font-size-regular;
    line-height: 2rem;
    margin-left: 0.4rem;
  }
}

.labelIcon {
  display: flex;
  align-items: center;
  margin-right: 0.4rem;
}

.error {
  border-color: var(--kp-color-form-border-error) !important;
}

.displayError {
  color: var(--kp-color-content-alert);
  min-height: 1.6rem;
  line-height: 1.6rem;
  font-size: $font-size-x-small;
  display: block;

  &.hideEmptyError {
    &:empty {
      display: none;
    }
  }
}
