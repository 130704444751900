@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.asLink {
  color: var(--kp-color-content-interactive-secondary);
  cursor: pointer;
}

.asLink.isUnderline:hover,
.asLink.isUnderline:focus {
  text-decoration: underline;
}

.asLink.primary {
  color: var(--kp-color-content-interactive-primary);
}

.asLink.disabled {
  text-decoration: none !important;
  cursor: default !important;
  pointer-events: none !important;
  color: var(--kp-color-disabled-dim);
}
