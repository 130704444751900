@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.inputHolder {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}

.inputRow {
  display: inline-flex;
  position: relative;
}

.label {
  display: flex;
  margin-bottom: 0.4rem;
  font-size: $font-size-small;
  line-height: 1.8rem;
  color: var(--kp-color-content-regular-primary);
  @include respond-below(sm) {
    font-size: $font-size-regular;
    line-height: 2rem;
  }

  .subLabel {
    font-size: $font-size-small;
    color: var(--kp-color-content-regular-tertiary);
  }
}

.label.isDisabled {
  color: var(--kp-color-disabled-dim);
}

.inputRow input {
  width: 100%;
  height: 3.6rem;
  border-radius: 0.2rem;
  padding: 0.8rem;
  border: 0.1rem solid var(--kp-color-form-border-default);
  color: var(--kp-color-content-regular-primary);
  background-color: var(--kp-color-form-bg-input);
  box-shadow: var(--shadow-3);
  transition: border-color 0.3s ease;
  will-change: border-color;

  @include respond-below(sm) {
    font-size: $font-size-regular;
    line-height: 2rem;
    height: 4.4rem;
    border-radius: 0.4rem;
  }
  &.label input::placeholder {
    color: var(--kp-color-content-regular-tertiary);
    opacity: 1;
  }
}

.inputRow.isDisabled input {
  border-color: var(--kp-color-form-border-disabled);
}

.inputRow input::placeholder {
  color: var(--kp-color-content-regular-secondary);
  opacity: 1;
}

.inputRow input:hover {
  box-shadow: var(--shadow-3);
  border-color: var(--kp-color-form-border-hover);
}

.inputRow input:hover::placeholder {
  color: var(--kp-color-content-regular-tertiary);
}

.inputRow input:required {
  box-shadow: none;
}

.inputRow input:invalid {
  box-shadow: none;
}

.inputRow input:focus {
  background-color: var(--kp-color-form-bg-input);
  caret-color: var(--kp-color-form-border-active);
  border-color: var(--kp-color-form-border-active);
}
.inputRow input:read-only:focus,
.inputRow input:read-only:hover {
  background-color: unset;
  caret-color: var(--kp-color-form-border-disabled);
  border-color: var(--kp-color-form-border-disabled);
  box-shadow: unset;
}

.inputRow input:focus::placeholder {
  color: var(--kp-color-content-regular-tertiary);
  opacity: 1;
}

.inputHolder.inputError input {
  background-color: var(--kp-color-form-bg-input);
  border-color: var(--kp-color-form-border-error) !important;
}

.error {
  display: block;
  min-height: 1.6rem;
  line-height: 1.6rem;
  font-size: $font-size-x-small;
  color: var(--kp-color-form-border-error);
}

.clearHolder {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  cursor: pointer;
  padding: 0 0.4rem;
}
.clearHolder .clear {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--kp-color-fill-interactive-secondary);
}

.clearHolder:hover .clear {
  background-color: var(--kp-color-fill-interactive-secondary-hover);
}

.inputHolder.isTrailingInner .trailingInner {
  position: absolute;
  right: 0;
  height: 100%;
}

.counter {
  margin-left: 0.4rem;
  color: var(--kp-color-content-regular-tertiary);
}
