@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.search {
  width: 100%;

  @include respond-below(sm) {
    &.homePageSearch input {
      font-size: $font-size-small;
    }
  }
}

.search input {
  height: 3.6rem !important;
  border: 0.1rem solid var(--kp-color-form-border-default);
  border-radius: 0.2rem 0 0 0.2rem;
  background-color: var(--kp-color-form-bg-input);

  @include respond-below(sm) {
    height: 4.4rem !important;
    border: 0.1rem solid var(--kp-color-form-border-active) !important;
    border-radius: 0.4rem 0rem 0rem 0.4rem !important;
    -webkit-border-radius: 0.4rem 0rem 0rem 0.4rem !important;
    background-color: var(--kp-color-form-bg-input);
    font-size: $font-size-regular;
    line-height: 2rem;
  }
}

.search input:hover {
  border-color: var(--kp-color-form-border-hover);
  background-color: var(--kp-color-form-bg-input);
}

.search input:focus {
  border-color: var(--kp-color-form-border-active);
}

.search input::placeholder {
  color: var(--kp-color-content-regular-secondary);

  @include respond-below(sm) {
    color: var(--kp-color-content-regular-secondary) !important;
  }
}

.search input:focus::placeholder {
  color: var(--kp-color-content-regular-tertiary);
}

.buttonInner {
  padding: 0.8rem 1.6rem 0.8rem 0;
  @include respond-below(sm) {
    height: 100%;
    padding: 0.8rem 1.6rem 0.8rem 0.8rem !important;
  }
}

.searchHolder {
  position: relative;

  @include respond-below(sm) {
    width: 100%;
  }
}
