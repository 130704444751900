@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.center {
  margin: 0 auto;
  width: 1012px;
}

.centerHeight {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6.8rem;
}

.search {
  transition-delay: 0.1s;

  .animated {
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    background-color: var(--kp-color-bg-primary);
    box-shadow: none;
    will-change: transform;
    z-index: -1;

    transform: translate3d(0, -180px, 0);
    transition: transform 400ms $animation-function-1;

    &[data-state='hide'] {
      transition: transform 50ms ease-out;
      transform: translate3d(0, -500px, 0);
    }

    &[data-state='show'] {
      transform: translate3d(0, -1px, 0);
    }
  }
}

.searchHolder {
  position: relative;
  z-index: 4;
}

.searchFilters {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  background-color: var(--kp-color-bg-primary);
  box-shadow: none;
  will-change: transform;
  z-index: -1;

  &.isOpen {
    box-shadow: inset 0px -1px 0px var(--kp-color-border-default);
  }
}

.searchInputHolder {
  min-width: 49rem;
  width: 100%;
}

.detailedFilter {
  width: 1004px;
  backface-visibility: hidden;
  margin: 0 auto 1.6rem auto;
  padding-top: 1.6rem;
}

.userSearchButton {
  border-radius: 0 !important;
}

.searchButton {
  border-radius: 0 0.2rem 0.2rem 0 !important;
}

.inputInnerHolder {
  display: inline;
}

.inputRow {
  display: flex;
  position: relative;
}

.onKpCheckbox {
  margin-left: 1.6rem;
  width: 18rem;
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  z-index: -2;
  transform: translateZ(0);
  background-color: var(--kp-color-transparent-overlay-desktop);
  animation-name: hide;
  animation-duration: 100ms;
  animation-fill-mode: forwards;

  &.isActive {
    animation-name: show;
    animation-duration: 200ms;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
